import * as React from "react"

const NotFoundPage = () => {
  return (
    <main>
      <title>Удобный софт - страница не найдена</title>
      <h1>404 - Страница не найдена</h1>
    </main>
  )
}

export default NotFoundPage
